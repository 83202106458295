<!--  -->
<template>
  <div class="content">
    <top-title>
      <span slot="left">接口管理</span>
      <span slot="right">接口管理</span>
    </top-title>
    <div class="data border">
      <div class="inner">
        <el-table :data="tableData" style="width: 100%" v-loading="loading">
          <el-table-column fixed prop="name" label="接口名称" width="200"></el-table-column>
          <el-table-column prop="endTime" label="到期时间"></el-table-column>
          <el-table-column prop="surplusCount" label="剩余次数"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                @click.native.prevent="apiInfoHandler(scope.row.id)"
                type="text"
                size="small"
              >详情</el-button>
              <el-button
                @click.native.prevent="$router.push({name:'invokeList',query:{id:scope.row.id}})"
                type="text"
                size="small"
              >调用详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <pagination
        :total="page.total"
        :current-page="page.current"
        :page-size="page.size"
        @refreshData="queryList"
      ></pagination>
    </div>
    <form-info v-if="apiInfoVisible" ref="apiInfo"></form-info>
  </div>
</template>

<script>
import formInfo from './api-info.vue'
import TopTitle from "@/components/common/top-title";
import FortressPagination from "@/components/common/pagination/pagination.vue";
import { apiPage } from "@/api/api.js";
export default {
  data () {
    return {
      apiInfoVisible: false,
      memberId: "",
      page: {
        current: 1,
        size: 6,
        total: 0,
      },
      tableData: [],
      queryForm: {},
      loading: false,
    };
  },
  created () {
    this.queryList();
  },

  components: {
    formInfo,
    TopTitle,
    pagination: FortressPagination,
  },

  computed: {},

  methods: {
    queryList (page) {
      this.loading = true;
      this.tableData = [];
      if (page) {
        this.page.current = page.current ? page.current : this.page.current;
        this.page.size = page.size ? page.size : this.page.size;
      }
      this.queryForm.current = this.page.current;
      this.queryForm.size = this.page.size;
      this.queryForm.memberId = JSON.parse(localStorage.getItem("userInfo")).id;
      apiPage(this.queryForm).then((resp) => {
        this.loading = false;
        if (resp.code == 0) {
          this.tableData = resp.data.records;
          this.page.total = resp.data.total;
          //查询页大于总页数，重新查询
          let cu =
            this.page.total % this.page.size == 0
              ? parseInt(this.page.total / this.page.size)
              : parseInt(this.page.total / this.page.size + 1);
          if (cu > 0 && this.page.current > cu) {
            this.queryList({ current: cu });
          }
        } else {
          this.$message({
            message: resp.msg,
            type: "error",
          });
        }
      });
    },
    //状态格式化
    // statusFmt (row) {
    //   let name = "";
    //   if (row.status == "1") {
    //     name = "未支付";
    //   } else {
    //     name = "已支付";
    //   }
    //   return name;
    // },
    //api调用详情
    apiInfoHandler (id) {
      this.apiInfoVisible = true
      this.$nextTick(() => {
        this.$refs.apiInfo.init(id)
      })
      // this.$router.push({ name: "invokeList", query: { id: id } });
    },
  },
};
</script>
<style lang='scss' scoped>
.content {
  width: 100%;
  .data {
    padding: 20px;
    margin: 0 0 20px 0;
    background: #fff;
    // border: thin solid rgba(0, 0, 0, 0.12);
    .inner {
      margin-bottom: 20px;
      .col-name {
        margin-right: 20px;
        color: #999;
        font-size: 14px;
        width: 7%;
      }
      .col-content {
        span {
          font-size: 14px;
          color: #666;
          font-weight: bold;
        }
      }
    }
  }
}

em {
  position: relative;
  &.success {
    color: $success;
    &::before {
      content: '已认证';
    }
  }
  &.error {
    color: $danger;
    &::before {
      content: '未认证';
    }
  }
}

@media screen and (min-width: 992px) {
  .content {
    .part1 {
      .col-md9 {
        margin-bottom: 0;
      }
      .price {
        margin-right: 20px;
      }
    }
  }
}
</style>