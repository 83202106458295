<!--  -->
<template>
  <div>
    <el-drawer
      :visible.sync="drawer"
      direction="rtl"
      :before-close="handleClose"
      size="400px"
      custom-class="drawer-box"
      title="详情"
    >
      <div class="drawer-inner">
        <ul class="list">
          <li class="line">
            接口名称：
            <p>{{apiInfos.shopInterfaceDto.name||'暂无'}}</p>
          </li>
          <li class="line">
            图片：
            <p>
              <img :src="apiInfos.shopInterfaceDto.imgUrl" alt />
            </p>
          </li>
          <li class="line">
            介绍：
            <p v-html="apiInfos.shopInterfaceDto.info||'暂无'"></p>
          </li>

          <li class="line">
            到期时间：
            <p>{{apiInfos.endTime}}</p>
          </li>
          <li class="line">
            剩余次数：
            <p>{{apiInfos.surplusCount}}</p>
          </li>
        </ul>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { apiInfo } from "@/api/api.js";

export default {
  data () {
    return {
      drawer: true,
      apiInfos: {
        shopInterfaceDto:{}
      }
    };
  },

  components: {},

  computed: {},

  methods: {
    init (id) {
      this.drawer = true
      apiInfo(id).then(res => {
        this.apiInfos = res.data
      })
    },
    handleClose (done) {
      done()
    }
  }
}

</script>
<style lang='scss' scoped>
::v-deep .el-drawer__wrapper {
  .drawer-box {
    .el-drawer__header {
      margin-bottom: 20px;
      padding: 10px 20px;
      font-size: 16px;
      color: #666;
      border-bottom: 1px solid #e8eaec;
    }
  }
}

.drawer-inner {
  padding: 0 15px;
  font-size: 14px;
  color: #666;
  .list {
    .line {
      padding: 0px 0 20px;
      p {
        color: #999;
        padding: 10px 0;
        // border-bottom: 1px solid #e8eaec;
      }
    }
  }
}
</style>